import { configureStore } from '@reduxjs/toolkit'
// import masterDataReducer from "../slice/onload"
import masterReducer from './slice/onload'

const store = configureStore({
  reducer: {
    master : masterReducer,
  },
})

export default store;