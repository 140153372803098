import React from 'react'
import DesktopNavbar from './DesktopNavbar'


const Header = () => {
  return (
    <>
      <div id="site-header" className="site-header header-static sticky-header">
        <DesktopNavbar />
      </div>
    </>
  )
}

export default Header
