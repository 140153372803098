import React, {Suspense} from 'react'
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import {ScrollToTop} from '../hooks'
import * as view from '../views'
import * as ROUTES from '../constant/routes'
import DesktopLayout from '../components/layout/DesktopLayout'

const Approuter = () => {
  return (
    <Router>
      <>
        <Suspense>
            <Routes>
            <Route path={ROUTES.Home} element={
              <ScrollToTop>
                <DesktopLayout>
                  <view.Home/>
                </DesktopLayout>
              </ScrollToTop>
            }>   
            </Route>
            </Routes>
        </Suspense>
      </>
    </Router>
  )
}

export default Approuter
