import React from 'react'
import axios from 'axios';

const Apis = () => {
    axios.defaults.baseURL = "https://admin.shopersbay.com/";

    axios.defaults.headers.common['authentication'] = "549681be7a669a80f686e15f8d8b2d79";

    const getMasterDataService = async () => {
        const response = await axios.get("asapi/getMasterData")
        return response;
    };

    const getNavigationlist = async () =>{
        const response =  await axios.get("asapi/getNavigationList");
        return response;
    }

    return {
        getMasterDataService: getMasterDataService,
        getNavigationlist: getNavigationlist,

    }
}

export default Apis
