import React, {useEffect} from 'react'
import './App.css';
import Approuter from './routers/Approuter';
import { useDispatch, useSelector } from 'react-redux';
import {fetchMasterData, fetchNavigation} from './redux/slice/onload'

function App() {
  const dispatch = useDispatch()
  
  useEffect(()=>{
    dispatch(fetchMasterData())
    dispatch(fetchNavigation())
  },[])
  return (
    <>
    <Approuter />
    </>
  );
}

export default App;
