import React, {useState} from 'react'
import {NavLink, useNavigate} from 'react-router-dom'
import logo from '../../images/logo/auditsbay_logo.png'
import { FaAngleDown } from "react-icons/fa6";
import {useSelector} from "react-redux"

const DesktopNavbar = () => {

  const state = useSelector((state) => state);
  const navigate = useNavigate()
  const [navlist, setNavlist] = useState(null)

  // if(state)
  // const navlist = state.master.navigation.result;
   console.log("state",state)
  const handlclick = (url) => {
    document.getElementById("custome_sub_manu").style.opacity = '0';
    document.getElementById("custome_sub_manu").style.visibility = 'hidden';
    navigate(url)
  }

  return (
    <>
      <div className="header-desktop">
      <div className="octf-main-header main-header main-style coporate-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-3 col-lg-2">
              <div className="space-7"></div>
              <div className="site-logo">
                <NavLink to="/">
                  <img src={logo} alt="" />
                </NavLink>
              </div>
              <div className="space-7"></div>
            </div>
            <div className="col-lg-10 col-xl-9">
              <div className="d-flex flex-wrap justify-content-end">
                <nav id="site-navigation" className="main-navigation">
                  <ul className="menu">
                    {
                      state.master.navigation != null && state.master.navigation.result.length > 0 && state.master.navigation.result.map((ele, i) => {
                        return (
                          <li className={`${ele.hasOwnProperty("children") ? "menu-item-has-children" : ""}`} menu-item-has-children key={i}>
                            <NavLink to={ele.url} 
                            onMouseOver={() => {
                              if(i == 0){
                                document.getElementById("custome_sub_manu").style.opacity = '1';
                                document.getElementById("custome_sub_manu").style.visibility = 'visible';
                              }else{
                                document.getElementById("custome_sub_manu").style.opacity = '0';
                                document.getElementById("custome_sub_manu").style.visibility = 'hidden';
                              }
                            

                            }}
                          
                            
                            
                            >{ele.name} {ele.hasOwnProperty("children") && <FaAngleDown />}</NavLink>
                            {
                              ele.hasOwnProperty("children") &&

                              <ul className="sub-menu" id="custome_sub_manu">
                                {ele.children.map((element, ind) => {
                                  return (
                                    <React.Fragment key={ind}>
                                      {
                                        ele.name === "Products" ?
                                          <li><NavLink to={element.url} target="_balnk">{element.name}</NavLink></li>
                                          :
                                          <li><a onClick={() => handlclick(element.url)} >{element.name} </a></li>
                                      }

                                    </React.Fragment>
                                  )
                                })}

                              </ul>
                            }

                          </li>
                        )
                      })

                    }
                  </ul>
                </nav>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default DesktopNavbar
